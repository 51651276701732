import React, { useMemo, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import classnames from "classnames";
import Header from "./Header";
import { graphql, useStaticQuery } from "gatsby";
import wrenchImage from "../assets/images/wrench.png"; // https://icons8.com/icons/dusk
import copyrightImage from "../assets/images/copyright.png"; // https://icons8.com/icons/dusk
import twitterImage from "../assets/images/twitter.png";
import emailImage from "../assets/images/email.png";
import seoImage from "../assets/images/name-logo--dark.png";
import SEO from "./Elements/SEO/SEO";
const currentYear = new Date().getFullYear();

const Layout = ({ children, className, post, ...rest }) => {
  const [isDark, setIsDark] = useState(false);

  useEffect(() => {
    if (
      localStorage?.theme === "dark" ||
      (!("theme" in localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.querySelector("html").classList.add("dark");
      setIsDark(true);
    } else {
      document.querySelector("html").classList.remove("dark");
      setIsDark(false);
    }
  }, []);

  const data = useStaticQuery(graphql`
    query {
      site {
        buildTime
      }
    }
  `);

  const dateString = useMemo(() => {
    const date = new Date(data.site.buildTime);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }, [data.site.buildTime]);

  return (
    <div className="bg-gray-100 dark:bg-gray-900 text-gray-700 dark:text-gray-100 transition-colors min-h-screen flex flex-col">
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" />
      </Helmet>
      <SEO post={{ image: seoImage, ...post }} />
      <Header isDarkDefault={isDark} />
      <main className={classnames("mx-auto container p-8 pb-12 flex-grow", className)} {...rest}>
        {children}
      </main>
      <footer className="shadow-sm bg-gray-100 dark:bg-gray-800">
        <section className="container mx-auto p-8 flex flex-wrap justify-between">
          <p className="text-sm flex items-center">
            <img src={copyrightImage} alt="Copyright" className="h-7 mr-1" />
            Kevin Borrill {currentYear}
          </p>
          <p className="text-sm flex items-center">
            <img src={wrenchImage} alt="" role="presentation" className="h-7 mr-1" />
            <span>
              This site was last built at {dateString} <br /> Built with:{" "}
              <a
                href="https://www.gatsbyjs.com/"
                target="blank"
                rel="noopenner noreferrer"
                className="text-purple-500 dark:text-purple-400"
              >
                Gatsby
              </a>{" "}
              &{" "}
              <a
                href="https://tailwindcss.com/"
                rel="noopenner noreferrer"
                target="blank"
                className="text-purple-500 dark:text-purple-400"
              >
                Tailwind CSS
              </a>
            </span>
          </p>
          <div>
            <a
              className="m-1 hover:text-purple-500 focus:text-purple-500 font-extrabold hover:animate-wiggle focus:animate-wiggle inline-block outline-none"
              href="mailto://kevinborrill@gmail.com"
              target="_blank"
              rel="noopenner noreferrer"
              aria-label="Contact me"
            >
              <img role="presentation" alt="" src={emailImage} className="inline h-10" />
            </a>
            <a
              className="m-1 hover:text-purple-500 focus:text-purple-500 font-extrabold hover:animate-wiggle focus:animate-wiggle inline-block outline-none"
              href="https://www.twitter.com/kev2480"
              target="_blank"
              rel="noopenner noreferrer"
              aria-label="My Twitter"
            >
              <img role="presentation" alt="" src={twitterImage} className="inline h-10" />
            </a>
          </div>
        </section>
      </footer>
    </div>
  );
};

export default Layout;
