import React, { useEffect } from "react";
import useToggle from "../hooks/useToggle";
import { Link } from "gatsby";
import { useSiteMetadata } from "../hooks/useSiteMetadata";
import DarkModeToggle from "./Elements/DarkMode/DarkModeToggle";

const Header = ({ isDarkDefault }) => {
  const { shortTitle } = useSiteMetadata();
  const [isDark, toggleDark] = useToggle(isDarkDefault);

  useEffect(() => {
    if (isDark !== isDarkDefault) {
      toggleDark();
    }
  }, [isDarkDefault, toggleDark]); // eslint-disable-line

  const toggleDarkMode = () => {
    document.querySelector("html").classList.toggle("dark");
    localStorage.theme = isDark ? "light" : "dark";
    toggleDark();
  };

  return (
    <header>
      <nav className="flex flex-wrap items-center justify-between p-8 rounded-lg mx-auto container z-0">
        <Link to="/" className="flex items-center c-title-link mx-auto sm:mx-0 z-10">
          <div className="text-2xl font-extrabold">
            <h1 className="bg-clip-text text-transparent bg-gradient-to-br from-purple-500 to-red-500 hover:to-purple-500 font-bold uppercase tracking-widest">
              {shortTitle}
            </h1>
          </div>
        </Link>
        <div className="mx-auto sm:mx-0 flex items-center">
          <DarkModeToggle
            className="h-12 w-12 hover:animate-wiggle focus:animate-wiggle inline-block c-dark-mode-toggle"
            aria-label={isDark ? "Lighten up?" : "Switch off the lights!"}
            isActive={isDark}
            onClick={toggleDarkMode}
          />
        </div>
      </nav>
    </header>
  );
};

export default Header;
