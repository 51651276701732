import React from "react";
import { useSpring, animated as a } from "react-spring";

const properties = {
  moon: {
    r: 9,
    transform: "rotate(40deg)",
    cx: 12,
    cy: 4,
    opacity: 0,
    fill: "#fff",
    stroke: "#fff"
  },
  sun: {
    r: 6,
    transform: "rotate(90deg)",
    cx: 30,
    cy: 0,
    opacity: 1,
    fill: "#ff9a00",
    stroke: "#ff9a00"
  },
  config: { mass: 4, tension: 250, friction: 35 }
};

const DarkModeToggle = ({ onClick, isActive, ...rest }) => {
  const { transform, r, cx, cy, opacity, fill, stroke } = properties[isActive ? "moon" : "sun"];

  const svgContainerProps = useSpring({ transform, config: properties.config });
  const centerCircleProps = useSpring({ r, fill, config: properties.config });
  const maskedCircleProps = useSpring({ cx, cy, config: properties.config });
  const linesProps = useSpring({ opacity, stroke, config: properties.config });

  const toggle = e => {
    onClick(e);
  };

  return (
    <button {...rest} onClick={toggle} style={{ outline: "none" }}>
      <a.svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 24 24"
        fill="none"
        style={svgContainerProps}
      >
        <mask id="mask">
          <rect x="0" y="0" width="100%" height="100%" fill="white" />
          <a.circle cx="12" cy="4" r="9" fill="black" strokeWidth="0" style={maskedCircleProps} />
        </mask>
        <a.circle cx="12" cy="12" r="9" mask="url(#mask)" style={centerCircleProps} />
        <a.g style={linesProps} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <line x1="12" y1="1" x2="12" y2="3" />
          <line x1="12" y1="21" x2="12" y2="23" />
          <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
          <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
          <line x1="1" y1="12" x2="3" y2="12" />
          <line x1="21" y1="12" x2="23" y2="12" />
          <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
          <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
        </a.g>
      </a.svg>
    </button>
  );
};

export default DarkModeToggle;
